@import url('https://fonts.googleapis.com/css?family=Titillium+Web');
body {
  font-family: 'Titillium Web', 'Roboto', sans-serif !important;
}

/* .App {
  text-align: center;
} */

.header {
  color: #fff;
}

.row-main {
  padding: 5px;
}

.main-tabs {
  background-color: #f0f2f5;
  padding: 0 10px 0 5px !important;
}

.info.legend {
  background-color: #fafafa;
  opacity: 0.8;
}

.info.legend>h1 {
  color: #eeeeee;
}
/* 
.info.legend>svg {
  height: 400px;
} */


.leaflet-container {
  /* width: 100%; */
  height: 90vh;
}

.simulatedMap{
  height: 85vh;
}

.single-card .ant-card-head-title {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.chart-container {
  background-color: #eeeeee;
}

.social-icons {
  font-size: 25px !important;
}

ul.share-menu li {
  padding: 0px !important;
}

.avatarImg img {
  object-fit: contain !important;
}

.analytics-img {
  width: 100%;
}
