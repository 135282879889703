// // Drawer
.drawer-menu-left{
    .ant-drawer-title{
        font-size: 20px !important;
    }
}

.btn-custom1{
    background-color: #847f7f !important;
    color: #fff !important;
    &:hover{
        border-color: #fff !important;
    }
}
.left-pane.tab-center{
    .ant-tabs-tab {
        font-size: 13px;
    }
}
.tab-center {
    .ant-tabs-nav {
        width: 100% !important;
    }
    .ant-tabs-tab {
        width: 50% !important;
        text-align: center;
    }
    .ant-tabs-tab {
        width: 49% !important;
        text-align: center;
    }
}

.analytics-modal {
    .ant-modal-header {
        background-color: #f58812;
        text-align: center;
        font-size: 24px;

        .ant-modal-title {
            font-size: 24px;
            font-weight: bold;
        }
    }
}

.map-tab{
    .ant-tabs-tab{
        &:hover{
            color: #fafafa !important;
        }
        background-color: #847f7f !important;
        color: #bababa;
        font-weight: normal;
    }
    .ant-tabs-tab-active{
        color: #fff !important;
        font-weight: 600 !important;
    }
}

.date-slider{
    .ant-slider-mark{
        display: none;
    }
}

.fbShareAdvice{
    p{
        color: #000000;
        font-size: 17px;
        margin-bottom: 1px !important;
    }
}