@import url(https://fonts.googleapis.com/css?family=Titillium+Web);

/* body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  font-family: 'Titillium Web', 'Roboto', sans-serif !important;
}

/* .App {
  text-align: center;
} */

.header {
  color: #fff;
}

.row-main {
  padding: 5px;
}

.main-tabs {
  background-color: #f0f2f5;
  padding: 0 10px 0 5px !important;
}

.info.legend {
  background-color: #fafafa;
  opacity: 0.8;
}

.info.legend>h1 {
  color: #eeeeee;
}
/* 
.info.legend>svg {
  height: 400px;
} */


.leaflet-container {
  /* width: 100%; */
  height: 90vh;
}

.simulatedMap{
  height: 85vh;
}

.single-card .ant-card-head-title {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.chart-container {
  background-color: #eeeeee;
}

.social-icons {
  font-size: 25px !important;
}

ul.share-menu li {
  padding: 0px !important;
}

.avatarImg img {
  object-fit: contain !important;
}

.analytics-img {
  width: 100%;
}

.drawer-menu-left .ant-drawer-title{font-size:20px !important}.btn-custom1{background-color:#847f7f !important;color:#fff !important}.btn-custom1:hover{border-color:#fff !important}.left-pane.tab-center .ant-tabs-tab{font-size:13px}.tab-center .ant-tabs-nav{width:100% !important}.tab-center .ant-tabs-tab{width:50% !important;text-align:center}.tab-center .ant-tabs-tab{width:49% !important;text-align:center}.analytics-modal .ant-modal-header{background-color:#f58812;text-align:center;font-size:24px}.analytics-modal .ant-modal-header .ant-modal-title{font-size:24px;font-weight:bold}.map-tab .ant-tabs-tab{background-color:#847f7f !important;color:#bababa;font-weight:normal}.map-tab .ant-tabs-tab:hover{color:#fafafa !important}.map-tab .ant-tabs-tab-active{color:#fff !important;font-weight:600 !important}.date-slider .ant-slider-mark{display:none}.fbShareAdvice p{color:#000000;font-size:17px;margin-bottom:1px !important}

